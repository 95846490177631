<template>
    <div class="error-wrapper">
        <transition-group name="fade" tag="div">
            <Item
                v-for="message in list"
                :key="message.id"
                :item.sync="message"
                :progress.sync="progress[message.id]"
            ></Item>
        </transition-group>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
const Item = () => import('./item');
export default {
    name: 'Snackbar',
    components: {
        Item,
    },
    data: () => ({
        interval: null,
        progress: {},
    }),
    computed: {
        ...mapGetters({
            list: 'snackbar/list',
        }),
        reactiveProgressList() {
            return this.progress;
        },
    },
    methods: {
        startInterval() {
            this.interval = setInterval(() => {
                for (let i = 0, l = this.list.length, now = new Date().getTime(); i < l; i++) {
                    if (typeof this.list[i] === 'undefined') continue;
                    const currentMessage = this.list[i];
                    const diff = now - currentMessage.time;
                    Vue.set(this.progress, currentMessage.id, ((diff / currentMessage.timeout) * 100).toFixed(1));
                    if (currentMessage.time + currentMessage.timeout <= now) {
                        this.$store.commit('snackbar/REMOVE_MESSAGE', currentMessage.id);
                    }
                }
            }, 10);
        },
        stopInterval() {
            clearInterval(this.interval);
            this.interval = null;
            this.progress = {};
        },
    },
    watch: {
        list(val) {
            if (val && val.length > 0) {
                if (!this.interval) {
                    this.startInterval();
                }
            } else this.stopInterval();
        },
    },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.error-wrapper {
    position: fixed;
    z-index: 2599;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
</style>
